<!-- 销售站点主管账号 -->
<template lang="">
<div style="height:100%;padding:0 20px 10px;box-sizing:border-box">
    <div class="selectionBar">
        <div class="flex flex-ai-c flex-wrap">
            <label>场景：</label>
            <el-button type="primary" block size="small" style="margin-left:auto" @click="edit()">新建</el-button>
        </div>
    </div>
    <!-- 表格 -->
    <div class="table" style="height:calc( 100% - 175px )">
        <el-table ref="table" :data="list" highlight-current-row :cell-style="{color:'#515a6e',borderColor:'#E8EAEC'}" :header-cell-style="{background:'#F8F8F9',color:'#515A6E'}" @sort-change='getList()' height="calc( 100% - 41px )">
            <el-table-column prop="name" label="品牌名称" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button plain type="success" size="mini" icon="el-icon-edit-outline" @click="edit(scope.row)"></el-button>
                    <el-button plain type="danger" size="mini" icon="el-icon-delete" @click="handleDel(scope.row.id)"></el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <!-- 新建编辑 -->
    <el-dialog :title="dialogTitle" :modal-append-to-body="false" :close-on-click-modal="false" :visible="dialogIsShow" width="40%" @close="dialogClose">
        <el-form :model="formData" ref="form" label-position="top" :show-message="false" :status-icon="true" class="alertForm">
            <el-form-item label="品牌名称" prop="name" required style="width:99%;margin-right:0">
                <el-input v-model.trim="formData.name" size="small" placeholder="请填写" :clearable="true"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogClose" size="small">取 消</el-button>
            <el-button type="primary" @click="handleSubmit" class="save" size="small">保 存</el-button>
        </div>
    </el-dialog>

</div>
</template>

<script>
export default {
    data: function() {
        return {
            searchValue: '', //导入input值
            select: {},
            list: [],
            curr: 1, //页码
            row: 10, //每页条数
            pages: 1, //总页数
            count: 0, //总条数

            formData: {},
            dialogTitle: '',
            dialogIsShow: false,
        }
    },
    mounted: function() {
        this.getList();
    },
    methods: {
        getList: function() {
            this.http.post('/admin.brand/getList', {
                curr: this.curr,
                row: this.row,
                search: this.searchValue
            }).then(re => {
                this.list = re.data;
            })
        },
        edit: function(row = {}) {
            if (typeof row.id == 'undefined') {
                this.dialogTitle = "新建品牌";
            } else {
                this.dialogTitle = "编辑品牌";
                this.formData = {};
                row.password='';
                this.formData = JSON.parse(JSON.stringify(row));
            }
            this.dialogIsShow = true
        },
        handleDel(id){
            this.$confirm('此操作将永久删除该条信息, 是否继续', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then( () => {
               this.http.post('/admin.brand/delete',{
                   id:id
               }).then(()=>{
                    this.getList();
                    this.$message({
                        message: '成功删除',
                        type: 'success'
                    })
               })
            })
        },
        //监听表单关闭事件
        dialogClose() {
            this.dialogIsShow = false;
            this.formData = {};
            this.$refs.form.resetFields();
        },
        handleSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.http.post('/admin.brand/edit', this.formData).then(() => {
                        this.dialogClose();
                        this.getList();
                        this.$message({
                            message: '操作成功',
                            type: 'success'
                        })
                    })
                }
            })
        },

        //翻页
        currentChange: function(curr) {
            this.curr = curr;
            this.getList();
        },
        //改变每页条数
        sizeChange: function(row) {
            this.row = row;
            this.getList();
        },
    }
}
</script>
